import React, { FC, useContext, useEffect } from 'react';
import { BrowserRouter, Link, Outlet, Route, Routes } from 'react-router-dom';
import { globalContext } from '../../common/GlobalStorage/GlobalStorage';
import Menu from '../../components/Menu/Menu';

interface SwitchRouterProps {}

const Index = React.lazy(() => import("./../../pages/0_Index/Index.lazy"));
const Wallet = React.lazy(() => import("../../pages/1_Wallet/Wallet.lazy"));
const Land = React.lazy(() => import("../../pages/2_Land/Land.lazy"));
const Versicherungssumme = React.lazy(() => import("../../pages/3_Versicherungssumme/Versicherungssumme.lazy"));
const Integration = React.lazy(() => import("../../pages/4_5_Integration/Integration.lazy"));
const Unterkonto = React.lazy(() => import("../../pages/4_Unterkonto/Unterkonto.lazy"));
const Nachweis = React.lazy(() => import("../../pages/5_Nachweis/Nachweis.lazy"));
const Kundendaten = React.lazy(() => import("../../pages/6_Kundendaten/Kundendaten.lazy"));
const Zusammenfassung = React.lazy(() => import("../../pages/7_Zusammenfassung/Zusammenfassung.lazy"));
const Unterlagen = React.lazy(() => import("../../pages/8_Unterlagen/Unterlagen.lazy"));

const SwitchRouter: FC<SwitchRouterProps> = () =>  {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
            element={<Index />}
        />
        <Route
          path="wallet"
          element={
            <React.Suspense fallback={<>...</>}>
              <Wallet />
            </React.Suspense>
          }
        />
        <Route
          path="wallet/:a"
          element={
            <React.Suspense fallback={<>...</>}>
              <Wallet />
            </React.Suspense>
          }
        />
        <Route
          path="land"
          element={
            <React.Suspense fallback={<>...</>}>
              <Land />
            </React.Suspense>
          }
        />
        <Route
          path="versicherungssumme"
          element={
            <React.Suspense fallback={<>...</>}>
              <Versicherungssumme />
            </React.Suspense>
          }
        />
        <Route
            path="integration"
            element={
              <React.Suspense fallback={<>...</>}>
                <Integration />
              </React.Suspense>
            }
          />
          <Route
            path="unterkonto"
            element={
              <React.Suspense fallback={<>...</>}>
                <Unterkonto />
              </React.Suspense>
            }
          />
          <Route
          path="nachweis"
          element={
            <React.Suspense fallback={<>...</>}>
              <Nachweis />
            </React.Suspense>
          }
        />
        <Route
          path="kundendaten"
          element={
            <React.Suspense fallback={<>...</>}>
              <Kundendaten />
            </React.Suspense>
          }
        />
        <Route
          path="zusammenfassung"
          element={
            <React.Suspense fallback={<>...</>}>
              <Zusammenfassung />
            </React.Suspense>
          }
        />
        <Route
          path="unterlagen"
          element={
            <React.Suspense fallback={<>...</>}>
              <Unterlagen />
            </React.Suspense>
          }
        />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
    </BrowserRouter>
  );
};

function Layout() {

  const { globalState } = useContext(globalContext);
  const className = globalState?.layoutCustomCSS || 'bg-gradient-to-r from-blue to-lightblue';

  useEffect(() => {
    if (globalState?.layoutCustomCSS) {
      const customCSSLink = document.getElementById('custom-css') as HTMLLinkElement;
      if (customCSSLink) {
        customCSSLink.href = globalState.layoutCustomCSS;
      }
    }
  });

  return (
    <div className={`main min-h-screen ${className}`}>
      {!globalState?.layoutCleanBackgound && <div className="bg bg-[url('assets/img/bg.svg')] bg-no-repeat bg-contain absolute w-96 h-96"></div>}
      {!globalState?.layoutCleanBackgound && <Menu />}
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default SwitchRouter;
